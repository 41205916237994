.modal{
  .modal-dialog{
    .modal-content{
      .modal-header{
        .close{
          span{
            font-size: 20px;
            font-weight: 400;
            color: #6a6a6a;

          }
        }
      }
    }
  }
}

.account{
  height: 550px;
  overflow-y: scroll;
}