$theme-colors: (
  "primary": #0074d9,
  "danger": #ff4136,
  "dark": #000,  
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";


.fix-width {
	width: 80vw;
 // height: 90vh;
//	overflow-y: auto;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

// Scrollbar Override
.scroll-inner {
	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar:horizontal {
		height: 10px;
	}
	&::-webkit-scrollbar-track {
		background-color: transparentize(#ccc, 0.7);
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: transparentize(#ccc, 0.5);
		box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
	}
}