/* Adjustment */
.container-scroller .btn {
  margin: 0px !important;
}
.bg-white-theme {
  background-color: #f1f2f4;
}
.nav-tabs .nav-link {
  background: #f5f5f5;
  color: black;
}
.nav-tabs .nav-link.active {
  color: #035fb3 !important;
  background-color: #fff !important;
  border-bottom: 2px solid #035fb3 !important;
}
.row {
  padding: 10px;
}
.border-neglect {
  border-width: 0px;
}
.tab-content {
  border-width: 0px;
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
}
.select-drop {
  border: 1px solid rgb(147, 147, 147);
  border-radius: 4px;
  padding: 4px;
}
.custom-btn {
  background: #fff;
  color: #007cb9;
  border: 2px solid #007cb9;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}
.side-sheet {
  box-shadow: 2px 4px 8px #585858;
  padding: 0px;
}
.custom-grey-btn {
  background: #f2f2f2;
  color: #212121;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: small;
}
.picker {
  color: #b2b2b3;
  color: #e0dede;
}
.no-margin {
  margin: 0px;
}

.bank-details-table {
  background: #eeeeee;
}

table {
  border-collapse: collapse;
  margin: 0px;
}
.no-glow {
  outline: none;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.my-drop {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-hover:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.login-hover-link {
  color: #fff;
}
.login-hover-link:hover {
  text-decoration: none;
  color: yellow;
  cursor: pointer;
}

.txt-prime {
  color: #010147;
}

/* Zebra striping */
/* tr:nth-of-type(odd) {
  background: #eee;
} */

th {
  background: #3498db;
  color: white;
  font-weight: bold;
}

td,
th {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 18px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* ADMIN TABLE STYLE */
.admin-table-style td,
th {
  padding: 10px;
  border: 2px solid #000;
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
}

.admin-table-style th {
  background: #3676d8;
  color: white;
  font-weight: bolder;
}
.admin-fix-width {
  width: 100%;
}
.admin-table-style tr:nth-of-type(odd) {
  background: #fff;
  font-weight: bolder;
}
.admin-table-style tr:nth-of-type(even) {
  background: #fff;
  font-weight: bolder;
}

/* NORMAL TABLE STYLE */
.normal-table-style td,
th {
  padding: 10px;
  border: 2px solid #000;
  text-align: left;
  font-size: 18px;
  font-weight: bolder;
}

.normal-table-style th {
  background: #3676d8;
  color: white;
  font-weight: bolder;
}
.normal-fix-width {
  width: 100%;
}
.normal-table-style tr:nth-of-type(odd) {
  background: #fff;
  font-weight: bolder;
}
.normal-table-style tr:nth-of-type(even) {
  background: #fff;
  font-weight: bolder;
}

/* DMT Table Style */

.dmt-table-style table {
  width: 100%;
  table-layout: fixed;
}
.dmt-tbl-header {
  background-color: #000;
}
.dmt-tbl-content {
  height: 300px;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.dmt-table-style th {
  padding: 8px 8px;
  text-align: left;
  font-weight: 800;
  font-size: 1rem;
  color: #fff;
  background: #4b56d2;
  text-transform: uppercase;
  border: none;
}
.dmt-table-style td {
  padding: 8px 8px;
  text-align: left;
  vertical-align: middle;
  font-weight: 800;
  font-size: 0.8rem;
  color: #000;
  border: solid 1px rgba(255, 255, 255, 0.1);
}
.dmt-table-style tr:nth-of-type(odd) {
  background: #ecf2ff;
  font-weight: bolder;
}
.dmt-table-style tr:nth-of-type(even) {
  background: #fff;
  font-weight: bolder;
}
.dmt-table-selected-left {
  border-left: 4px solid #4b56d2 !important;
}

/* DMT Table Style */

.hover {
  cursor: pointer;
}
.hover-2 {
  cursor: pointer;
}
.hover-2:hover {
  color: red !important;
}

/* Dashboard Table Design */
/* Dashboard */

.dashboard-table-style td,
th {
  padding: 10px;
  border: none;
  text-align: center;
  font-size: 18px;
}

.dashboard-table-style th {
  background: #ffffff;
  color: #000000;
  font-weight: bolder;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
}
.dashboard-fix-width {
  width: 100% !important;
}
.dashboard-table-style tr:nth-of-type(odd) {
  background: #ffffff;
  border-bottom: 1px solid #cccccc;
}
.dashboard-table-style tr:nth-of-type(even) {
  background: #caecfc;
  border-bottom: 1px solid #caecfc;
}

.dashboard-table-style td {
  color: #000000;
}

/* Income Table Design */
/* Income Table */

.income-calculator-table-style td,
th {
  padding: 10px;
  border: none;
  font-size: 0.8rem;
}

.income-calculator-table-style th {
  background: #ffffff;
  color: #000000;
  font-weight: bolder;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
}
.income-calculator-fix-width {
  width: 100% !important;
}
.income-calculator-table-style tr:nth-of-type(odd) {
  background: #ffffff;
  /* border-bottom: 1px solid #cccccc; */
}
.income-calculator-table-style tr:nth-of-type(even) {
  background: #ffffff;
  /* border-bottom: 1px solid #cccccc; */
}

.income-calculator-table-style td {
  color: #000000;
}

.nav-pills.nav-pills-custom .nav-link.active {
  background: #50c3c4;
}

.nav-pills.nav-pills-custom .nav-link.active {
  background: #50c3c4;
}
.nav-pills.nav-pills-custom .nav-link {
  border-radius: 1em !important;
}

.nav-pills.nav-pills-custom-2 .nav-link {
  border-radius: 0px !important;
}

@media print {
  .hide-on-print {
    display: none;
  }
}

/* Naveen  */

/* naveen */

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.d-class1 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  background: #fff !important;
  border-radius: 20px !important;
  font-family: "Poppins", sans-serif !important;
  position: relative !important;
}

.btn-ttt {
  border: none !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  background: linear-gradient(to right, #4fc3c6 0%, #7fc99f 100%) !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  cursor: pointer !important;
  font-family: Poppins !important;
}

.th-ffff {
  background: #ffffff !important;
  color: #000000 !important;
  padding: 0.5rem 1rem !important;
  border: none !important;
  font-family: Poppins !important;
}

.td-rrrr {
  border: none;
}

.btn-ttt:focus {
  border: none !important;
  scale: 1.1 !important;
  transition: 0.5s !important;
}

.btn-tttt {
  border: none !important;
  color: #ffffff !important;
  background: linear-gradient(to right, #65a7db 0%, #426bb4 100%);
  font-size: 0.8rem !important;
  cursor: pointer !important;
  position: relative !important;
  font-family: Poppins !important;
}

.btn-fffff {
  color: #000000 !important;
  background: none !important;
  font-size: 0.8rem !important;
  cursor: pointer !important;
  position: relative !important;
  font-family: Poppins !important;
}

.btn-fffff:hover {
  border: 1px solid black !important;
  background-color: none !important;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.d-class1 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  background: #fff !important;
  border-radius: 20px !important;
  font-family: "Poppins", sans-serif !important;
  position: relative !important;
}

.btn-ttt {
  border: none !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  background: linear-gradient(to right, #4fc3c6 0%, #7fc99f 100%) !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  cursor: pointer !important;
  font-family: Poppins !important;
}

.th-ffff {
  background: #ffffff !important;
  color: #000000 !important;
  padding: 0.5rem 1rem !important;
  border: none !important;
  font-family: Poppins !important;
}

.td-rrrr {
  border: none;
}

.btn-ttt:focus {
  border: none !important;
  scale: 1.1 !important;
  transition: 0.5s !important;
}

.btn-tttt {
  border: none !important;
  color: #ffffff !important;
  background: linear-gradient(to right, #65a7db 0%, #426bb4 100%);
  font-size: 0.8rem !important;
  cursor: pointer !important;
  position: relative !important;
  font-family: Poppins !important;
}

.btn-fffff {
  color: #000000 !important;
  background: none !important;
  font-size: 0.8rem !important;
  cursor: pointer !important;
  position: relative !important;
  font-family: Poppins !important;
}

.btn-fffff:hover {
  border: 1px solid black !important;
  background-color: none !important;
}

.flightbanner h1,
.flightbanner h3,
.flightbanner h6 {
  margin: 0;
  /* text-shadow: 2px 2px 6px #000; */
  text-align: left;
  font-family: Poppins;
}

.flightbanner {
  background: url("./assets/images/flight.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

@media (max-width: 997px) {
  .flightbanner {
    background: url("./assets/images/flight.png");
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center, center;
  }

  .flightbanner h1,
  .flightbanner h3,
  .flightbanner h6 {
    /* text-shadow: 2px 2px 6px #000; */
    text-align: left;
    font-size: 1.5em;
    font-family: Poppins;
    align-items: flex-start;
  }
}

.d-class23 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background: #fff;
  border-radius: 20px;

  font-family: "Poppins", sans-serif;
  position: relative;
}
.btn-primary-fff {
  background-color: #ffffff;
  border-color: #000000;

  color: black;

  font-family: "Poppins", sans-serif;
  position: relative;
}
.btn-primary-ffff {
  color: rgb(255, 255, 255);
  background: linear-gradient(90deg, #65a7db 0%, #3a6beb 100%);
  font-family: "Poppins", sans-serif;
  position: relative;
}

.btn-primary-ffffff {
  color: rgb(255, 255, 255);
  background: linear-gradient(90deg, #ec5665 0%, #c12740 100%);
  font-family: "Poppins", sans-serif;
  position: relative;
}

.form-label {
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  color: grey;
}

.btn-price {
  border: none !important;
  color: #ffffff !important;
  border-radius: 10px !important;

  font-size: 1.2 !important;
  cursor: pointer !important;
  position: relative !important;
  font-family: Poppins !important;
  width: 100% !important;
}

._loading_overlay_overlay {
  z-index: 100000 !important;
}

.react-tel-input .form-control{
  margin: 0px !important;
  width: 100% !important;
}


/* image preview */

.image-overlays {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.image-overlays img {
  max-width: 100%;
  max-height: 80vh;
}


.nav-pills.nav-pills-custom-5 .nav-link.active {
  background:#FFFFFF;
  color:#4267B2;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);

 
}


