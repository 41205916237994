


.tab1-container {
    border: 1px solid #ccc;
    border-radius: 3px;
    overflow: hidden;
    width: 400px;
   
    

  }
  
  .tab1-header {
    display: flex;
    justify-content: space-between;
    background-color: #EEF0F1;
    border-radius: 0.3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border: #d31616;

    display: flex;
    flex-direction: column;
  }
  
  .tab1-item {
    padding: 0px 0px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    margin: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;

color: #4267B2;

  }
  
  .tab1-item.active {
   
    background-color: #FFFFFF;
    border-radius: 0.5rem;
    margin: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;


    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
 
box-shadow: #FFFFFF;
color: #4267B2;
  
  }
  
  .tab1-content {
    padding: 10px;
  }

  .button:focus {
    
    box-shadow: none !important;
  
    }